import React from 'react';
import './about.css';
import { Link } from 'react-router-dom';
import { BiCheckShield } from 'react-icons/bi';
import { TbArrowsMaximize, TbRotateClockwise } from 'react-icons/tb';
import { IoIosTimer } from 'react-icons/io';
import AllTrans from '../static/images/all_trans.png';
import World from '../static/images/Worldmap.jpg';
import ServeDev from '../static/images/serv-dev.jpg';
import Partner from '../static/images/partner.png';

const About = () => {
  return (
    <div className='about'>
      <h1 className='page-head'>About</h1>
      <div className='overview'>
        <div className='img'>
          <img src={AllTrans} alt='' />
        </div>
        <div>
          <h4 className='c-name'>CanKen Logistics Limited</h4>
          <p>
            CanKen Logistics Limited was established in 2015 with a focus on
            providing complete Sea-to-Air logistics solutions custom designed to
            meet the needs of our different customers. The company is based in
            Eldoret (Eldoret International Airport), as well as in Nairobi with
            a global outreach of partners serving our customers.
          </p>
          <p>
            At Eldoret International Airport, CanKen Logistics successfully
            secured a cargo facility and we currently manage approximately 60%
            of all the traffic coming into Kenya through imports as well as
            exports.
          </p>
        </div>
      </div>
      <div className='insights'>
        <h5>Volumes handled at the airport over the last three years</h5>
        <div className='table'>
          <table>
            <tr className='thd'>
              <th>Description</th>
              <th>2019</th>
              <th>2020</th>
              <th>2021</th>
              <th>2022*</th>
              <th>TOTAL</th>
              <th>%</th>
            </tr>
            <tr>
              <th>Airport Volumes</th>
              <td>7,709,870</td>
              <td>12,084,178</td>
              <td>12,229,027</td>
              <td>5,869,512</td>
              <td>37,892,587</td>
              <td>100%</td>
            </tr>
            <tr>
              <th>CanKen Volumes</th>
              <td>3,818,122</td>
              <td>6,615,319</td>
              <td>7,396,967</td>
              <td>3,104,239</td>
              <td>20,934,647</td>
              <td>55%</td>
            </tr>
          </table>
        </div>
        <p className='t-note'>
          <span>Note:</span>
          2022* – Data is from January to September 2022.
        </p>
      </div>
      <div className='coverage'>
        <div className='cvg1'>
          <p>
            Our expansive footprint across Africa, Middle East and globally
            allow us to have coverage in key areas of our operations and in
            building a compelling business case for our clients globally.
          </p>
        </div>
        <div className='world-img'>
          <img src={World} alt='World map' />
        </div>
        <div className='cvg2'>
          <p>
            CanKen has an outstanding track record in the delivery of mission
            critical Multimode Services enabling us to handle multifaceted
            movements on a turnkey basis to even the most remote locations.
          </p>
        </div>
      </div>
      <div className='bg-dotted'></div>
      <div className='vis-mis'>
        <div className='vision'>
          <h4 className='header-underln'>Our Vision</h4>
          <p>
            To be a pioneer in freight management innovation, providing
            customer-centered freight forwarding & logistics solutions as a
            valued partner.
          </p>
        </div>
        <div className='mission'>
          <h4 className='header-underln'>Our Mission</h4>
          <p>
            Our Mission is to develop the most efficient, cost-effective
            solutions in logistics services both domestic and cross border.
          </p>
        </div>
      </div>
      <div
        className='service-dev'
        style={{ backgroundImage: `url(${ServeDev})` }}
      >
        <div className='service-dev-overlay'>
          <div className='s-d-cont'>
            <p>Our service delivery is characterized by:</p>
            <ul>
              <li>
                <IoIosTimer /> Timeliness
              </li>
              <li>
                <TbArrowsMaximize />
                Flexibility
              </li>
              <li>
                <BiCheckShield />
                Safety
              </li>
              <li>
                <TbRotateClockwise />
                Reliability
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='capacity'>
        <p>
          CanKen Logistics sees the value in meeting sustainable developments
          goals particularly for local capacity building. We are committed to
          creating employment opportunities for the local communities in the
          areas we operate in through training, exposure, and more importantly
          creating a team of highly experienced personnel that understand the
          needs of our customers.
        </p>
      </div>
      <div className='partner'>
        <div>
          <img src={Partner} alt='' />
        </div>
        <div>
          <p>
            Our management team is highly experienced and follows pertinent
            industry guidelines and international standards.
          </p>
          <p>
            CanKen Logistics has secured partnerships with various shippers and
            air cargo carriers as well as certified customs clearance companies.
            We are committed to offer a seamless approach in all our services
            guaranteeing quality and timely delivery for our customers.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
