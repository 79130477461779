import React from 'react';
import './alert_modal.css';
// import { Modal, Button } from 'react-bootstrap';
import Mailto from 'react-protected-mailto';
import { MdClose } from 'react-icons/md';

const AlertModal = (props) => {
  const { status, quote, handleClose } = props;

  return (
    <>
      <div
        show
        onHide={handleClose}
        className={`modal ${status ? 'modal-success' : 'modal-error'}`}
      >
        <div className='modal-header closeButton'>
          <div className='modal-title'>
            {status
              ? quote
                ? 'Quote requested'
                : 'Message Sent'
              : quote
              ? 'Request Not Sent'
              : 'Message Not Sent'}
          </div>
          <div className='modal-close'>
            <MdClose fontSize={20} onClick={handleClose} />
          </div>
        </div>

        <div className='modal-body'>
          {status ? (
            <>
              <p>
                Thank you for {quote ? 'your request!' : 'contacting us!'} We
                will be in touch with you shortly.
              </p>
              <p>Have a great day ahead!</p>
            </>
          ) : (
            <>
              <p>
                Sorry, there was an error while sending your{' '}
                {quote ? 'request' : 'message'}. Please try sending again.
              </p>
              <p>
                If the problem persists, kindly write to us through{' '}
                <Mailto email='info@gritsoft.systems' />
              </p>
            </>
          )}
        </div>

        <div className='modal-footer'>
          <button variant='secondary' onClick={handleClose}>
            Close
          </button>
        </div>
      </div>
      <div className='modal-bg' onClick={handleClose}></div>
    </>
  );
};

export default AlertModal;
