import React from 'react';
import GoBack from '../components/GoBack';
import cargoPlane from '../static/images/plane_bay.jpg';

const AirFreight = () => {
  return (
    <div className='container service'>
      <h3>
        <GoBack />
        <span>Air Freight</span>
      </h3>

      <img src={cargoPlane} alt='' />
      <p>
        Due to the sensitivity and time pressures involved in airfreight, CanKen
        Logistics develops comprehensive airfreight service plans tailored to
        meet our customers' specific requirements. This is accomplished by
        learning our customers' exact objectives with regards to their internal
        and external requirements.
      </p>
      <p>
        Sensitive and valuable goods are often subject to extreme time pressures
        and deadlines for everyone involved. Therefore, our Airfreight
        Department develops a customized service plan for each customer, and
        then implements our required Operational Handling Procedures to ensure a
        high-quality service.
      </p>
      <p>
        Our team of Air Freight professionals follows through on each and every
        shipment so that our customers can be assured of real-time status
        reports on all import and export air freight shipments at all times.
      </p>
      <p>
        For oversize cargo transportation by air, we provide professional
        know-how related to the handling of part and full charter service
        including complete door to door delivery logistics on a global scale.
      </p>
      <p className='ul'>Our Air Freight Services include:</p>
      <ul>
        <li>
          Complete Logistics Programs (pick, pack, assembly, warehousing,
          documentation and handling) for:
          <ul>
            <li> Hazardous Cargo</li>
            <li> Live Stock Cargo</li>
            <li> Fashion & Luxury Goods</li>
            <li> Auto and Automotive part</li>
            <li> Oil & Gas Industry Cargo</li>
            <li> Pharmaceutical Cargo</li>
            <li> Precious Metal Cargo</li>
            <li> Among others</li>
          </ul>
        </li>
        <li>
          Other Services:
          <ul>
            <li> Import/Export Consolidation & Deconsolidation</li>
            <li> Pick, Pack, Assembly and Distribution</li>
            <li> Split and Full Air Charter</li>
            <li> Worldwide Door to Door Services</li>
            <li> Sea/Air & Road/Air Service</li>
            <li> Customs Clearance Service</li>
            <li> Pick-up and Delivery Service</li>
            <li> Packing and Crating</li>
          </ul>
        </li>
      </ul>

      <GoBack content='Go Back To Services' />
    </div>
  );
};

export default AirFreight;
