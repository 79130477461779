import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BsExclamationOctagon,
  BsFillExclamationOctagonFill,
} from 'react-icons/bs';

import './not_found.css';

const NotFound = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className='container not-found'>
      <div className='not-found_header'>
        <BsExclamationOctagon />
        <h2 className=''> Page Not Found</h2>
      </div>
      <p className='lead'>
        Looks like you've followed a broken link or entered a URL that doesn't
        exist.
      </p>
      <button className='' onClick={goBack}>
        Go Back
      </button>
    </div>
  );
};

export default NotFound;
