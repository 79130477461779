import React from 'react';

import plane from '../static/images/airplane_dep.png';
import planeSm from '../static/images/airplane_dep_sm.png';
import cloud from '../static/images/clouds.jpeg';
import clouds from '../static/videos/clouds.mp4';

import './hero.css'
import useWindowWidth from '../utils/useWindowWidth';

const Hero = () => {

  const width = useWindowWidth();
  return (
    <>
      <div className='hero'>
        <video id="background-video" autoPlay loop muted poster={cloud}>
          <source src={clouds} type="video/mp4" />
        </video>
      </div>
      <div className='hero_bg' style={{ backgroundImage: (width > 1150 ? `url(${plane})` : `url(${planeSm})`) }}>
        <div className="hero_overlay">
          <div className="hero_heading">
            <h1>People</h1><h1>Partnership</h1><h1>Performance</h1>
            <p>Let's go together living the experience and linking better</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Hero;