import React from 'react';
import ServiceItem from './ServiceItem';
import services_data from './../constants/services_data';

const ServiceList = () => {
  return (
    <div className='service-list'>
      {services_data.map((s) => (
        <ServiceItem
          image={s.image}
          title={s.title}
          descr={s.description}
          lnk={s.lnk}
          key={s.id}
        />
      ))}
    </div>
  );
};

export default ServiceList;
