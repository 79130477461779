import React from 'react';
import logo from '../static/images/canken_logo_250x100.png';
import Navbar from '../components/Navbar';

import './header.css'

const Header = () => {
  return (
    <header className='container'>
      <img src={logo} className='logo' alt='logo' />
      <Navbar />
    </header>
  )
}

export default Header