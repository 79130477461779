import { ParallaxProvider } from 'react-scroll-parallax';
import { Home, About, Services, Contact, NotFound } from './pages';
import { Header, Footer } from './containers';
import { Routes, Route } from 'react-router-dom';

import './App.css';

function App() {
  return (
    <ParallaxProvider>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='about' element={<About />} />
        <Route path='services/*' element={<Services />} />
        <Route path='contact' element={<Contact />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      <Footer />
    </ParallaxProvider>
  );
}

export default App;
