import React from 'react';
import warehouse from '../static/images/warehouse.jpg';
import GoBack from './../components/GoBack';

const WarehouseManagement = () => {
  return (
    <div className='container service'>
      <h3>
        <GoBack />
        <span>Warehouse Management</span>
      </h3>
      <img src={warehouse} alt='' />
      <p>
        CanKen Logistics offers secure storage facilities at all key
        destinations regionally and globally. Our warehouses are among the
        safest places to store cargo.
      </p>
      <p>
        The warehouses are well protected and equipped with electronic theft and
        fire alarms backed by round-the-clock security guards. For hygiene, in
        addition to normal hygiene standards, we conduct periodic checks carried
        out by specialist partner companies. All goods are properly protected so
        that they can remain in our warehouse based on the customer’s needs.
      </p>
      <p>
        CanKen Logistics’ extensive warehousing and distribution system will
        pick, pack, store and distribute consignments anywhere in the region by
        efficient modes of transport. Special warehousing facilities for
        household goods and personal effects are also available at all CanKen
        warehouse designated locations.
      </p>
      <p className='ul'>Our Warehousing Services include:</p>
      <ul>
        <li>Short- and long-term storage</li>
        <li>Cargo loading and unloading</li>
        <li>Cargo labelling and packing</li>
        <li>Fumigation and Pest control</li>
        <li>Packing and Crating services</li>
        <li>Cargo Distribution</li>
        <li>POD Reporting</li>
      </ul>

      <GoBack content='Go Back To Services' />
    </div>
  );
};

export default WarehouseManagement;
