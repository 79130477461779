import React, { useRef } from 'react'
import { MdMenu } from 'react-icons/md';
import { MdClose } from 'react-icons/md';
import { NavLink } from 'react-router-dom';

import './navbar.css'

const Navbar = () => {

    const [toggleMenu, setToggleMenu] = React.useState(false);

    const menu_layout = useRef();

    const opening = () => {
        setToggleMenu(true);
        setTimeout(() => {
            menu_layout.current.classList.add('open');
        }, 100);
    }

    const closing = () => {
        menu_layout.current.classList.remove('open');
        setTimeout(() => {
            setToggleMenu((prev) => !prev)
        }, 1000);
    };

    return (
        <>
            <div className='navbar'>
                <NavLink to="/" className=''>Home</NavLink>
                <NavLink to="/about" className=''>About</NavLink>
                <NavLink to="/services" className=''>Services</NavLink>
                <NavLink to="/contact" className=''>Contact</NavLink>
            </div>
            <div className="navbar-smallscreen">
                <MdMenu className="overlay__open" color="#fff" fontSize={46} onClick={opening} />
                {toggleMenu && (
                    <div ref={menu_layout} className='navbar-smallscreen_overlay'>
                        <MdClose fontSize={46} className="overlay__close"
                            onClick={closing} />
                        <div className="navbar-smallscreen_links">
                            <NavLink to="/" onClick={closing}>Home</NavLink>
                            <NavLink to="/about" className='' onClick={closing}>About</NavLink>
                            <NavLink to="/services" className='' onClick={closing}>Services</NavLink>
                            <NavLink to="/contact" className='' onClick={closing}>Contact</NavLink>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default Navbar