import React, { useState, useEffect, useRef } from 'react';
import './contact.css';
import { useLocation, Link } from 'react-router-dom';

import Axios from 'axios';
// import { Helmet } from 'react-helmet';
import Mailto from 'react-protected-mailto';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AlertModal from '../containers/AlertModal';
import { AiOutlineFacebook } from 'react-icons/ai';
import { AiOutlineInstagram } from 'react-icons/ai';
import { AiOutlineWhatsApp } from 'react-icons/ai';

const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

const ContactSchema = Yup.object().shape({
  company: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Name is required!'),
  email: Yup.string()
    .email('Email is not valid!')
    .required('Email address is required!'),
  phone: Yup.string()
    .min(4, 'Too Short!')
    .matches(phoneRegExp, 'Phone number is not valid! +255 700 000000')
    .required('Phone number is required!'),
  address: Yup.string().min(5, 'Too Short!'),
  message: Yup.string()
    .min(50, 'Message should have 50 characters and above!')
    .required('Message is required!'),
});

const QuoteSchema = Yup.object().shape({
  company: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Name is required!'),
  email: Yup.string()
    .email('Email is not valid!')
    .required('Email address is required!'),
  phone: Yup.string()
    .min(4, 'Too Short!')
    .matches(phoneRegExp, 'Phone number is not valid! +255 700 000000')
    .required('Phone number is required!'),
  address: Yup.string().min(5, 'Too Short!'),
  project: Yup.string()
    .min(50, 'Project should have 50 characters and above!')
    .required('Project is required!'),
});

const Contact = (props) => {
  let location = useLocation();
  const [quote, setQuote] = useState(
    location.state ? (location.state.quote === 'true' ? true : false) : false
  );
  const [show, setShow] = useState(false);
  const [sent, setSent] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    location.state
      ? location.state.quote === 'true'
        ? setQuote(true)
        : setQuote(false)
      : setQuote(false);
  }, [location]);

  return (
    <>
      {/* <Helmet>
        <title>Contact | PMchome Logistics</title>
        <link rel='canonical' href='https://pmchomelogistics.com/contact' />
      </Helmet> */}
      <div className='contact'>
        <h1 className='page-head'>{quote ? `Get a Quote` : `Get In Touch`}</h1>
        <div className='row'>
          <div className='col col-8 bg-light pt-2'>
            <Formik
              initialValues={{
                company: '',
                name: '',
                email: '',
                phone: '',
                address: '',
                message: '',
                project: '',
              }}
              validationSchema={quote ? QuoteSchema : ContactSchema}
              onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
                setSubmitting(true);
                Axios({
                  method: 'post',
                  // url: 'https://canken.clementnzau.com/contact.php',
                  headers: { 'content-type': 'application/json' },
                  data: values,
                })
                  .then((result) => {
                    if (result.data.sent) {
                      resetForm();
                      setSent(true);
                      setShow(true);
                    } else {
                      setErrors(true);
                      setErrors(result.data.message);
                      setSubmitting(false);
                      setSent(false);
                      setShow(true);
                    }
                  })
                  .catch((error) => {
                    setErrors(error.message);
                    setSent(false);
                    setShow(true);
                  });

                setSubmitting(false);
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                touched,
                isValid,
                handleSubmit,
                isSubmitting,
                errors,
              }) => (
                <>
                  {show && (
                    <AlertModal
                      status={sent}
                      quote={quote}
                      handleClose={handleClose}
                    />
                  )}
                  <form onSubmit={handleSubmit}>
                    {/* <div className='row'> */}
                    {/* <div className='col col-5' sm={12} md={5}> */}

                    <div className='form-group flx-full' controlid='name'>
                      <input
                        className='form-control'
                        autoFocus
                        size='sm'
                        type='text'
                        placeholder=' '
                        name='name'
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isinvalid={errors.name && touched.name}
                      />
                      <div className='form-label'>
                        Contact Name <span className='text-danger'>*</span>
                      </div>
                      <span class='focus-border'></span>
                      <div className='form-control-feedback' type='invalid'>
                        {errors.name && touched.name ? errors.name : null}
                      </div>
                    </div>

                    <div className='form-group pr' controlid='email'>
                      <input
                        className='form-control'
                        size='sm'
                        type='email'
                        placeholder=' '
                        name='email'
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isinvalid={errors.email && touched.email}
                      />
                      <div className='form-label'>
                        Email address <span className='text-danger'>*</span>
                      </div>
                      <span class='focus-border'></span>
                      <div className='form-control-feedback' type='invalid'>
                        {errors.email && touched.email ? errors.email : null}
                      </div>
                    </div>

                    <div className='form-group pl' controlid='phone'>
                      <input
                        className='form-control'
                        size='sm'
                        type='tel'
                        placeholder=' '
                        name='phone'
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isinvalid={errors.phone && touched.phone}
                      />
                      <div className='form-label'>
                        Phone No. <span className='text-danger'>*</span>
                      </div>
                      <span class='focus-border'></span>
                      <div className='form-control-feedback' type='invalid'>
                        {errors.phone && touched.phone ? errors.phone : null}
                      </div>
                    </div>

                    <div className='form-group pr' controlid='company'>
                      <input
                        className='form-control'
                        size='sm'
                        type='text'
                        placeholder=' '
                        name='company'
                        value={values.company}
                        onChange={handleChange}
                      />
                      <div className='form-label'>Company Name</div>
                      <span class='focus-border'></span>
                    </div>

                    <div className='form-group pl' controlid='address'>
                      <input
                        className='form-control'
                        size='sm'
                        type='text'
                        placeholder=' '
                        name='address'
                        value={values.address}
                        onChange={handleChange}
                      />
                      <div className='form-label'>Address</div>
                      <span class='focus-border'></span>
                    </div>

                    <div
                      className='form-group flx-full'
                      controlid={quote ? 'project' : 'message'}
                    >
                      <textarea
                        className='form-control'
                        size='sm'
                        as='textarea'
                        placeholder=' '
                        // rows='5'
                        name={quote ? 'project' : 'message'}
                        value={quote ? values.project : values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isinvalid={
                          quote
                            ? errors.project && touched.project
                            : errors.message && touched.message
                        }
                      />
                      <div className='form-label'>
                        {quote ? 'Project' : 'Message'}{' '}
                        <span className='text-danger'>*</span>
                      </div>
                      <span class='focus-border'></span>
                      <div className='form-control-feedback' type='invalid'>
                        {quote
                          ? errors.project && touched.project
                            ? errors.project
                            : null
                          : errors.message && touched.message
                          ? errors.message
                          : null}
                      </div>
                    </div>
                    <button
                      className='float-right my-3'
                      variant='primary'
                      type='submit'
                      disabled={
                        !isValid ||
                        isSubmitting ||
                        Object.keys(touched).length === 0
                        // (Object.keys(touched).length === 0 &&
                        //   touched.constructor === Object)
                      }
                    >
                      {isSubmitting ? (
                        <>
                          {/* <Spinner
                                  as='span'
                                  animation='grow'
                                  variant='light'
                                  size='sm'
                                />{' '} */}
                          {quote ? `Requesting` : `Sending`}
                        </>
                      ) : quote ? (
                        `Request Quote`
                      ) : (
                        `Send Message`
                      )}
                    </button>
                    {/* </div> */}
                    {/* </div> */}
                  </form>
                </>
              )}
            </Formik>
          </div>

          <div className='col col-4 contact-widget'>
            <h6 className='badge'>Email:</h6>
            <p className='mb-4'>
              <Mailto email='canken@cankencargo.com' />
            </p>

            <h6 className='badge'>Phone:</h6>
            <ul className='phone-list'>
              <li>
                <Mailto tel='+254-53-2062665' />
              </li>
              <li>
                <Mailto tel='+254-725-487-652' />
              </li>
            </ul>
            <h6 className='badge'>Social:</h6>
            <div className='social-lnks'>
              {/* <Link to="" className=''><AiOutlineTwitter /> Twitter</Link> */}
              <a
                href='https://www.facebook.com/'
                target='_blank'
                rel='noreferrer'
              >
                <AiOutlineFacebook />
              </a>
              <a
                href='https://www.instagram.com/'
                target='_blank'
                rel='noreferrer'
              >
                <AiOutlineInstagram />
              </a>
              <a href='https://wa.me/' target='_blank' rel='noreferrer'>
                <AiOutlineWhatsApp />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
