import React from 'react';
import hzdWarehse from '../static/images/haz-warehse.jpg';
import GoBack from './../components/GoBack';

const DangerousGoods = () => {
  return (
    <div className='container service'>
      <h3></h3>
      <h3>
        <GoBack />
        <span>Dangerous Goods</span>
      </h3>
      <img src={hzdWarehse} alt='' />
      <p>
        Dangerous cargo consists of substances that can pose risks to health,
        property, safety or to the environment. This classification is on
        account of the poisonous, flammable or corrosive nature of the cargo.
        Therefore, efficient storage and transportation solutions for dangerous
        goods are a mandate when it comes to handling cargo of such nature.
      </p>
      <p>
        CanKen Logistics offers state-of-the-art storage and handling services
        for dangerous goods. All nine classes of dangerous goods are handled
        with the support of our global network through all means of transport,
        i.e. air, sea, road as well as rail.
      </p>
      <p>
        Our facilities enable storage of hazardous & explosive materials per DG
        classification, in compliance with safety standards. Additionally, we
        also offer packaging, customs clearance, labelling and documentation for
        dangerous goods.
      </p>

      <GoBack content='Go Back To Services' />
    </div>
  );
};

export default DangerousGoods;
