import shp from '../static/images/ship.jpg';
import warehouse from '../static/images/warehouse1.png';
import proj from '../static/images/proj_mngmnt.png';
import plane from '../static/images/plane_bay1.jpg';
import hazardous from '../static/images/hazardous.jpg';

const services_data = [
  {
    image: shp,
    title: 'Sea Freight',
    description:
      'Sea Freight is one of the key elements in multimodal transportation service. In order to offer the best service possible',
    lnk: '/sea-freight',
    id: 0,
  },
  {
    image: plane,
    title: 'Air Freight',
    description:
      'Due to the sensitivity and time pressures involved in airfreight, CanKen Logistics develops comprehensive airfreight service plans tailored',
    lnk: '/air-freight',
    id: 1,
  },
  {
    image: warehouse,
    title: 'Warehouse Management',
    description:
      'CanKen Logistics offers secure storage facilities at all key destinations regionally and globally. Our warehouses are among the safest places to store cargo',
    lnk: '/warehouse-management',
    id: 2,
  },
  {
    image: proj,
    title: 'Project Logistics Services',
    description:
      'Our Project Division can manage your entire project – from the initial consultations during the budgeting phase right down to the final on-site delivery. Having delivered various prestigious projects in challenging areas',
    lnk: '/project-logistics',
    id: 3,
  },
  {
    image: hazardous,
    title: 'Transport, Storage and Handling of Dangerous Goods',
    description:
      'Dangerous cargo consists of substances that can pose risks to health, property, safety or to the environment. This classification is on account of the poisonous, flammable or corrosive nature of the cargo',
    lnk: '/dangerous-goods',
    id: 4,
  },
];

export default services_data;
