import React from 'react';
import servicesBg from '../static/images/services.png';
import frtMng from '../static/images/freight_mngmnt.png';
import ServiceList from '../components/ServiceList';
import { BsCheck2Circle } from 'react-icons/bs';

import './services.css';
import { Route, Routes } from 'react-router-dom';
import SeaFreight from './SeaFreight';
import NotFound from './NotFound';
import AirFreight from './AirFreight';
import WarehouseManagement from './WarehouseManagement';
import ProjectLogistics from './ProjectLogistics';
import DangerousGoods from './DangerousGoods';

const Services = () => {
  return (
    <>
      <div className='services'>
        <h1 className='page-head'>Our Services</h1>

        <Routes>
          <Route
            path='/'
            element={
              <>
                <div className='srv-hero'>
                  <img src={servicesBg} alt='' />
                  <div>
                    <b>With us, you have:</b>
                    <ul className='check-list'>
                      <li>
                        the <i>strength</i>
                      </li>
                      <li>
                        the <i>capacity</i> &
                      </li>
                      <li>
                        a vast and reliable <i>network</i>
                      </li>
                    </ul>
                  </div>
                </div>
                <ServiceList />
              </>
            }
          />
          <Route path='/sea-freight' element={<SeaFreight />} />
          <Route path='/air-freight' element={<AirFreight />} />
          <Route
            path='/warehouse-management'
            element={<WarehouseManagement />}
          />
          <Route path='/project-logistics' element={<ProjectLogistics />} />
          <Route path='/dangerous-goods' element={<DangerousGoods />} />

          <Route path='*' element={<NotFound />} />
        </Routes>
      </div>
    </>
  );
};

export default Services;
