import React from 'react';
import GoBack from '../components/GoBack';
import ship from '../static/images/ship.jpg';

const SeaFreight = () => {
  return (
    <div className='container service'>
      <h3>
        <GoBack />
        <span>Sea Freight</span>
      </h3>
      <img src={ship} alt='' />
      <p>
        Sea Freight is one of the key elements in multimodal transportation
        service. In order to offer the best service possible, we have a
        dedicated Sea Freight Department specializing in FCL, LCL, Break-Bulk,
        Project Cargo and different types of cargoes on a global scale.
      </p>
      <p>
        The flexibility and range of Sea Freight Services offered by CanKen
        Logistics provides our customer economical alternatives to other, more
        costly modes of transportation.
      </p>
      <p>
        Our worldwide logistics services cover not only shipments from
        port-to-port, but also the complete door-to-door supply chain solution.
      </p>
      <p>
        Over the years, CanKen Logistics has fostered strong business
        relationship with all major international and regional ocean carriers.
      </p>
      <p className='ul'> Our Sea Freight Services include:</p>

      <ul>
        <li> FCL and LCL import and export</li>
        <li> Issuance and verification of shipping documentation</li>
        <li> Export and import customs clearance</li>
        <li> Certified industrial packing and crating</li>
        <li> Packing and crating of household goods</li>
        <li> Warehousing, assembly and distribution logistics</li>
        <li> Letter of credit processing</li>
        <li> Part & full vessel charter</li>
        <li> Conventional shipments (break-bulk, dry & liquid bulk)</li>
        <li> Sea/Air transportation</li>
        <li> Sea/Road and Sea/Rail Intermodal Services</li>
        <li> Transport and Marine Insurance</li>
      </ul>

      <GoBack content='Go Back To Services' />
    </div>
  );
};

export default SeaFreight;
