import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RiArrowGoBackLine } from 'react-icons/ri';

const GoBack = (props) => {
  const { content } = props;
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className='go-back' onClick={goBack}>
      <RiArrowGoBackLine className='return-icon' />
      <i>{content}</i>
    </div>
  );
};

export default GoBack;
