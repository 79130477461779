import React from 'react';
import proLogistics from '../static/images/pro_logistics.png';
import GoBack from './../components/GoBack';

const ProjectLogistics = () => {
  return (
    <div className='container service'>
      <h3>
        <GoBack />
        <span>Project Logistics</span>
      </h3>
      <img src={proLogistics} alt='' />
      <p>
        Our Project Division can manage your entire project – from the initial
        consultations during the budgeting phase right down to the final on-site
        delivery. Having delivered various prestigious projects in challenging
        areas, the CanKen Logistics is experienced in every aspect of project
        freight management.
      </p>
      <p className='ul'>Our areas of capacity include:</p>
      <ul>
        <li>Aircraft and ship charter</li>
        <li>Oil, Gas and Energy Transports</li>
        <li>Dangerous Goods transports</li>
        <li>Break Bulk, Ro/Ro and containerized freight solutions</li>
        <li>Heavy lift and over-dimension transports</li>
        <li>Military / Government shipments</li>
        <li>
          Supervision of port operations for heavy lifts and over-dimensional
          cargo
        </li>
        <li>Heavy lift planning and execution</li>
        <li>Complete documentation</li>
        <li>Specialist staff</li>
        <li>On-site representation</li>
      </ul>

      <GoBack content='Go Back To Services' />
    </div>
  );
};

export default ProjectLogistics;
