import React from 'react';
import { Link } from 'react-router-dom';
import {
  AiOutlineFacebook,
  AiOutlineTwitter,
  AiOutlineInstagram,
  AiOutlineWhatsApp,
} from 'react-icons/ai';
import Mailto from 'react-protected-mailto';

import './footer.css';

const Footer = () => {
  const yr = new Date().getFullYear();
  return (
    <footer className='container'>
      <div className='row'>
        <div className='footer_about'>
          <h3>CanKen</h3>
          <p>
            CanKen Logistics Limited focuses on providing complete Sea-to-Air
            logistics solutions that are custom designed to meet the needs of
            our different customers. The company is based in Eldoret, as well as
            in Nairobi with a global outreach of partners serving our customers.
          </p>
        </div>
        <div className='footer_address'>
          <h3>Address</h3>
          <p>
            P.O BOX 5772 - 30100, <br />
            ELDORET, KENYA. <br />
            TEL: <a href='tel:+254532062665'> +254 532 062 665</a>
            <br />
            MOB: <a href='tel:+254725487652'> +254 725 487 652</a> <br />
            Email:{' '}
            <a href='mailto:canken@cankencargo.com?subject=Inquiry from canken website&cc=cnt@clementnzau.com'>
              {' '}
              canken@cankencargo.com
            </a>
          </p>
        </div>
        {/* <div className='sm-wrap'> */}
        <div className='footer_social'>
          <h3>Find Us</h3>
          <div className='social-links'>
            <Link to='' className=''>
              <AiOutlineFacebook /> Facebook
            </Link>
            <Link to='' className=''>
              <AiOutlineTwitter /> Twitter
            </Link>
            <Link to='' className=''>
              <AiOutlineInstagram /> Instagram
            </Link>
            <Link to='' className=''>
              <AiOutlineWhatsApp /> WhatsApp
            </Link>
          </div>
        </div>
        <div className='footer_nav'>
          <h3>Navigation</h3>
          <div className='nav_links'>
            <Link to='/' className=''>
              Home
            </Link>
            <Link to='/about' className=''>
              About
            </Link>
            <Link to='/services' className=''>
              Services
            </Link>
            <Link to='/contact' className=''>
              Contact
            </Link>
          </div>
        </div>
        {/* </div> */}
      </div>
      <div className='row'>
        <p>Copyright © {yr} All rights reserved.</p>
      </div>
      <div className='row'>
        <a
          className='gss_l'
          href='https://clementnzau.com'
          target='_BLANK'
          rel='noopener noreferrer'
        >
          Site Developed By
        </a>
      </div>
    </footer>
  );
};

export default Footer;
