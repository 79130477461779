import React from 'react';
import { Hero, HomeServices } from '../containers';

const Home = () => {
  return (
    <>
      <Hero />
      {/* <HomeServices /> */}
    </>
  );
};

export default Home;
