import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const ServiceItem = (props) => {
  const { image, title, descr, lnk } = props;
  const navigate = useNavigate();
  const handleOnClick = () => navigate(`/services${lnk}`);
  return (
    <div className='service-item'>
      {/* <Link to={`/services${lnk}`}> */}
      <div
        className='service-card'
        // onClick={handleOnClick}
      >
        <div className='_img'>
          <img src={image} alt='' />
        </div>

        <h6>{title}</h6>
        <p>{descr}</p>
        <Link to={`/services${lnk}`}>
          Read more <span>→</span>
        </Link>
      </div>
      {/* </Link> */}
    </div>
  );
};

export default ServiceItem;
